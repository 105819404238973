import React, {createContext, useEffect, useReducer, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {firebaseAuth} from "../firebase";
import {onAuthStateChanged, signInWithEmailAndPassword, signOut} from "firebase/auth";

export const AuthContext = createContext();
const initialState = {
  isOnline: "",
  userPrivilege: null,
  viewNavbar: false
}

const AuthContextProvider = (props) => {

let navigate = useNavigate();
const usernameRef = useRef();
const passwordRef = useRef();

function reducer(state, action){
  switch(action.type) {
    case "isOnline": return {...state, isOnline: action.payload}
    case "userPrivilege": return {...state, userPrivilege: action.payload}
    case "viewNavbar": return {...state, viewNavbar: action.payload}
    default: throw new Error("something went wrong in reducer")
  }}

const [state, dispatch] = useReducer(reducer, initialState);

useEffect(() => { 
  onAuthStateChanged(firebaseAuth, (user) => {
    if (user) {
      user.getIdTokenResult().then(res => dispatch({type: "userPrivilege", payload: res})); 
      dispatch({type: "isOnline", payload: user.uid});
    } else {
      dispatch({type: "isOnline", payload: ""});
      console.log("Tidak ada user yang sedang aktif")
    }})}, [])
  
useEffect(() => {
  if(state.userPrivilege === null){
    dispatch({type: "viewNavbar", payload: false});
    }else if(
      state.userPrivilege.claims.attendance === true || 
      state.userPrivilege.claims.allacces === true){
      dispatch({type: "viewNavbar", payload: true})}else{
      dispatch({type: "viewNavbar", payload: false})}
}, [state.userPrivilege])
  
const handleSignIn = () => 
  signInWithEmailAndPassword(firebaseAuth, `${usernameRef.current.value}@kindergym.id`, passwordRef.current.value)
  .then(() => {
    navigate("/claimscheck")})
  .catch((error) => {
    const errorMessage = error.message;
    console.log(errorMessage);
    alert("Wrong username or password")}); 

const handleSignOut = () => {
  signOut(firebaseAuth)
  .then(() => dispatch({type: "isOnline", payload: ""}))
  .then(() => dispatch({type: "viewNabar", payload: false}))
  .then(() => dispatch({type: "userPrivilege", payload: null}))
  .then(() => navigate("/"))
}

  return(
    <AuthContext.Provider 
      value={{
        isOnline: state.isOnline, userPrivilege: state.userPrivilege, 
        viewNavbar: state.viewNavbar, usernameRef, passwordRef, 
        handleSignIn, handleSignOut, dispatch}}>
          {props.children} 
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
