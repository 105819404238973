import React, {useContext, useEffect} from "react";
import {AuthContext} from "../contexts/authcontexts";
import {useNavigate} from "react-router-dom";

export default function ClaimsCheck(props) {

  const classNames = `${props.widthHeight} ${props.centerVertically} ${props.centerHorizontally}`

  let navigate = useNavigate();
  const {userPrivilege, handleSignOut} = useContext(AuthContext);

  useEffect(() => {
    if(userPrivilege.claims.adminjambi === true || 
       userPrivilege.claims.allacces === true){
        navigate("/")
       }else{
        alert("You are not authorized");
        handleSignOut();
        navigate("/")
       }
  }, [userPrivilege])

  return (
    <div className={classNames}>
      ...Checking your authorization, please wait
    </div>
  )
}
